<template lang="pug">
q-menu.relative-position(
    :model-value="value"
    fit
    no-parent-event
    no-focus
    transition-show="jump-down"
    transition-hide="jump-up"
    :class="['wrapper menu', fixMenu ? 'menu-fix' : 'menu-fix-adult']"
    @hide="$emit('hide')")
    base-full-spinner(v-if="loading")
    q-card.row.no-wrap
        q-card-section.col-3.q-py-lg.q-px-none.bg-grey-3.no-border-radius
            router-link.row.q-px-lg.q-mb-md.items-center.cursor-pointer(to="/catalog-page?freeDelivery=true" title="Акция «Бесплатная доставка»" @click="hide")
                q-icon(
                    name="etabl:car_delivery"
                    color="positive"
                    size="20px"
                )
                .q-ml-md.line-height-24.text-positive.text-weight-medium
                    | Акция «Бесплатная доставка»
            q-list.text-primary
                template(v-for="(category, key) in newCategories")
                    q-item.q-px-lg(
                        v-ripple
                        clickable
                        active-class="active"
                        :active="category.code === currentCategory"
                        @click="setCurrentCategory(category.code)")
                        .row.items-center.justify-start
                            q-icon(
                                :name="`etabl:${category.code}`"
                                size="xs"
                                color="grey-4")
                            div
                                span.q-ml-md {{ category.name }}
                                span.q-pl-xs(v-if="freeDelivery") ({{ category.free_delivered_count }})
        q-card-section.col-6.q-py-lg(v-if="newCategories")
            ol.q-ma-none.q-pt-md(
                :style="`column-count: ${columnCount};`"
                style="list-style-type: none;")
                template(
                    v-for="(subcategory, key) in activeCategory?.categories")
                    li.text-grey-1.q-mb-sm.row.items-baseline(style="gap:0 8px; flex-wrap: nowrap;")
                        q-icon(name="circle" color="grey-4" size='6px')
                        router-link.text-primary(
                            @click="$emit('hide')"
                            :to="city ? `/${city.slug}/catalog/${subcategory.slug_name}` : `/catalog/${subcategory.slug_name}`"
                            :title="`${subcategory.slug_name}`")
                            div
                                span {{ subcategory.name }}
                                span.q-pl-xs(v-if="freeDelivery") ({{ subcategory.free_delivered_count }})
        q-card-section.col-3.q-pa-none.q-pt-lg.q-pr-lg
            .row.iris-banner-wrap.q-mb-lg
                div.desktop-md#adfox_173504686946399080
            .row.iris-banner-wrap.q-mb-lg
                div.desktop-md#adfox_173504687162649080


</template>

<script>
import { ref, computed, onMounted, watch } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import BaseFullSpinner from 'components/Base/BaseFullSpinner.vue';

export default {
    name: 'CategoryList',
    emits: ['hide'],
    components: {
        BaseFullSpinner,
    },
    props: {
        value: {
            type: Boolean,
            required: true,
        },
        fixMenu: {
            type: Boolean,
            default: () => false,
        },
    },
    setup(props, { emit }) {

        const router = useRouter();

        // состояние загрузки категорий
        const loading = ref(false);

        // объект стора
        const store = useStore();

        // город пользователя
        const city = computed(() => store.getters['cityForm/CITY']);

        const newCategories = computed(() => store.getters['categories/NCATEGORIES']);

        // заданите текущей категории
        const setCurrentCategory = (category) => store.commit('categories/SET_CURRENT_CATEGORY', category);

        // текущая выбранная категория
        const currentCategory = computed(() => store.getters['categories/CURRENT_CATEGORY']);

        // количество колонок
        const columnCount = computed(() => {
            if (activeCategory.value) {
                const { categories } = activeCategory.value;

                if (11 > categories < 20) {
                    return 2;
                }
            }
            return 'auto';
        });

        const activeCategory = ref(null);

        const hide = () => {
            store.commit('categories/SET_CURRENT_CATALOG_CATEGORY', null);
            emit('hide');
        };

        watch(currentCategory, () => {
            activeCategory.value = newCategories.value.find((item) => item.code == currentCategory.value);
        });

        watch(() => newCategories.value, () => {
            activeCategory.value = newCategories.value.find((item) => item.code == currentCategory.value);
        });
        watch(() => props.value, () => {
            if (process.env.CLIENT) {
                window.yaContextCb.push(() => {
                    Ya.adfoxCode.create({
                        ownerId: 5202103,
                        containerId: 'adfox_173504686946399080',
                        params: {
                            p1: 'dfnuw',
                            p2: 'ixfw',
                        },
                    });
                    Ya.adfoxCode.create({
                        ownerId: 5202103,
                        containerId: 'adfox_173504687162649080',
                        params: {
                            p1: 'dfnux',
                            p2: 'ixfw',
                        },
                    });

                });
                setTimeout(() => {
                    document.querySelectorAll('.iris-banner-wrap').forEach((elem) => {
                        if (elem.firstChild && elem.firstElementChild.firstChild) {
                            elem.firstElementChild.classList.add('not-empty');
                        }
                    });
                }, 1500);
            }
        });

        return {
            loading,
            columnCount,
            currentCategory,
            setCurrentCategory,
            hide,
            newCategories,
            activeCategory,
            city,
        };
    },
};
</script>

<style lang="scss" scoped>
.active {
    border-right: 2px solid $positive;
    background: $secondary;
    color: $positive;
    white-space: nowrap;
}

:deep(.menu-fix) {
    max-height: 100% !important;
}

.q-card {
    max-height: inherit;
    box-shadow: none;
}

.q-card :deep(.q-card__section) {
    max-height: inherit;

    &:nth-of-type(1) {
        //min-width: 340px;
    }

    ol {
        list-style-position: outside;
        list-style-type: disc;
        column-count: auto;
        column-fill: auto;
        height: 100%;
    }

    a {
        text-decoration: none;
    }
}
</style>
